<template>
  <form
    id="supplierForm"
    action
    data-vv-scope="supplier-form"
    @submit.prevent="validateForm('supplier-form')"
  >
    <v-row justify="center">
      <v-col class="py-0" cols="12">
        <label>{{ $t("inventorys.name_supplier") }}</label>
        <v-text-field
          outlined
          data-vv-validate-on="blur"
          class="inputs"
          hide-details
          dense
          v-model="supplier.supplier_name"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('supplier-form.name')"
          color="secondary"
          data-vv-name="name"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("inventorys.email_facturation") }}</label>
        <v-text-field
          hide-details
          data-vv-validate-on="blur"
          outlined
          class="inputs"
          dense
          v-model="supplier.email"
          v-validate="'email'"
          :error-messages="errors.collect('supplier-form.email')"
          color="secondary"
          data-vv-name="email"
        />
      </v-col>

      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("Phone") }}</label>
        <v-text-field
          outlined
          multiple
          dense
          class="inputs"
          v-model="supplier.phone"
          :items="brand"
          hide-details
          :error_messages="errors.collect('supplier-form.phone')"
          vv_name="phone"
        ></v-text-field>
      </v-col>

      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("Marca") }}</label>
        <v-select
          outlined
          multiple
          dense
          class="inputs"
          v-model="supplier.brands"
          :items="brand"
          item-text="brand_name"
          hide-details
          item-value="id"
          :error_messages="errors.collect('supplier-form.brand')"
          vv_name="brand"
          ><template v-slot:selection="{ item, index }">
            <span v-if="index < 2">
              <span>{{ item.brand_name }},</span>
            </span>
            <span v-if="index == 2" class="grey--text caption">
              (+{{ supplier.brands.length - 1 - 1 }}
              {{ $t("inventorys.brands") }})
            </span>
          </template></v-select
        >
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("inventorys.num_fiscal") }}</label>
        <v-text-field
          outlined
          data-vv-validate-on="blur"
          dense
          class="inputs"
          v-model="supplier.num_fiscal"
          hide-details
          v-validate="'dniOrNieOrPassport'"
          :error-messages="errors.collect('supplier-form.num_fiscal')"
          color="secondary"
          data-vv-name="num_fiscal"
        />
      </v-col>

      <v-col class="py-0" cols="12" md="12">
        <label>{{ $t("inventorys.direction_fiscal") }}</label>
        <v-text-field
          outlined
          data-vv-validate-on="blur"
          class="inputs"
          dense
          hide-details
          v-model="supplier.direction_fiscal"
          v-validate="''"
          :error-messages="errors.collect('supplier-form.direction')"
          color="secondary"
          data-vv-name="direction"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("inventorys.city") }}</label>
        <v-text-field
          outlined
          data-vv-validate-on="blur"
          class="inputs"
          hide-details
          dense
          v-model="supplier.city"
          v-validate="''"
          :error-messages="errors.collect('supplier-form.city')"
          color="secondary"
          data-vv-name="city"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("inventorys.postal") }}</label>
        <v-text-field
          outlined
          class="inputs"
          hide-details
          data-vv-validate-on="blur"
          dense
          v-model="supplier.postal_cody"
          v-validate="''"
          :error-messages="errors.collect('supplier-form.postal')"
          color="secondary"
          data-vv-name="postal"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("inventorys.province") }}</label>
        <v-text-field
          class="inputs"
          data-vv-validate-on="blur"
          outlined
          dense
          v-model="supplier.province"
          hide-details
          v-validate="''"
          :error-messages="errors.collect('supplier-form.province')"
          color="secondary"
          data-vv-name="province"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <label>{{ $t("inventorys.country") }}</label>
        <v-text-field
          class="inputs"
          data-vv-validate-on="blur"
          hide-details
          outlined
          dense
          v-model="supplier.country"
          v-validate="''"
          :error-messages="errors.collect('supplier-form.country')"
          color="secondary"
          data-vv-name="country"
        />
      </v-col>
    </v-row>
    <div class="pa-3 pt-6 pr-0 text-right" v-if="buttons !== false">
      <v-btn
        outlined
        @click="$emit('back')"
        style="height: 25px; width: 100px !important"
        class="mr-3"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
        type="submit"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </div>
  </form>
</template>
<script>
import constants from "@/constants";
import { mapActions } from "vuex";
export default {
  name: "SupplierForm",
  props: ["title", "supplier", "buttons"],
  mounted() {
    this.fetchProductBrand();
  },
  data() {
    return {
      userProcessed: false,
      menu: false,
      brand: [],
    };
  },
  methods: {
    ...mapActions("brand", ["getProductBrand"]),

    fetchProductBrand() {
      this.getProductBrand().then((data) => {
        this.brand = data;
      });
    },

    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        //console.log(this.supplier);
        this.$emit("validated", this.supplier);
        this.active = false;
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="sass">
.inputs .v-input__slot
  margin-top: -10px

#supplierForm
  label
    display: block
    height: 35px !important
</style>